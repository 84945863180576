import React from "react";
import excellogo from "./../../../assets/excel-icon.svg";
import FileSaver from "file-saver";
import Excel from "exceljs";
export default function ExportDataToExcel(props) {
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	const exportToCSV = async () => {
		if (props.data.length > 0) {
			const rowalignment = {
				vertical: "middle",
				horizontal: "center",
				wrapText: true,
			};
			let rows = [];
			const rowdata = props.data;
			rows = rowdata.map((e) => {
				let eachrow = [];
				for (var prop in e) {
					eachrow = [...eachrow, e[prop]];
				}
				return eachrow;
			});
			const workbook = new Excel.Workbook();
			const worksheet = workbook.addWorksheet("Admin Data", {
				properties: {
					defaultColWidth: 25,
					defaultRowHeight: 30,
				},
			});
			worksheet.insertRow(
				1,
				props.columns.map((e) => e.Header)
			);
			rows.forEach((r, i) => {
				worksheet.insertRow(i + 2, r);
				worksheet.getRow(i + 2).alignment = rowalignment;
			});
			worksheet.getRow(1).fill = {
				type: "pattern",
				pattern: "solid",
				fgColor: { argb: "d4d2d2" },
			};
			worksheet.getRow(1).font = { bold: true };
			worksheet.getRow(1).alignment = rowalignment;
			const buf = await workbook.xlsx.writeBuffer();
			const data = new Blob([buf], {
				type: fileType,
			});
			const date = new Date();
			FileSaver.saveAs(
				data,
				`Admin-Data-Report ${date.toDateString()}${fileExtension}`
			);
		}
	};
	return (
		<span
			className={`excel-image-wrapper ${
				props.data.length === 0 ? "disabled" : ""
			}`}
		>
			<img
				src={excellogo}
				alt="excel-img"
				className="excel-icon"
				onClick={exportToCSV}
			/>
		</span>
	);
}

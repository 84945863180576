import React, { useState } from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import {
  editOrDelete,
  datevalue,
  propcondition,
  HelpSection,
  CategorySec,
  EnableEmailSec,
  DateSec,
  CommentSec,
  FinalSelection,
  DefaultCommunicationModes,
  level1props,
  levelcommonprops,
  jsondata,
  ActionButtonSec,
  onlyAddconditon,
  onlyDeleteconditon,
  apicall,
  DeleteText,
  onlyView,
  deleteOrView,
  DescriptionSec,
} from "../../CommonBlocks/js/CommonBlock";
import { iteratechannels } from "../../AddNewLevels/js/AddNewLevels";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const checkComChannels = (level, data) => {
  console.log(level);
  console.log(data);
  return level === 1 ? iteratechannels(data) : true;
};
const onDefaultCommChecked = (e, requestApproveData, setRequestApproveData) => {
  if (!e.target.checked) {
    const index = requestApproveData.default.indexOf(e.target.name);
    requestApproveData.default.splice(index, 1);
    setRequestApproveData({
      ...requestApproveData,
      default: [...Array.from(new Set(requestApproveData.default))],
    });
  } else {
    setRequestApproveData({
      ...requestApproveData,
      default: [...requestApproveData.default, e.target.name],
    });
  }
};
function ApproveRejectScreen(props) {
  const id = props.category.id;
  let categoryData = {
    ...propcondition(props),
  };
  if (props.level === 1) {
    categoryData = { ...level1props(props), ...categoryData, id };
  } else {
    categoryData = { ...levelcommonprops(props), ...categoryData };
  }
  const [requestApproveData, setRequestApproveData] = useState(categoryData);
  const handleRequestChange = (e) => {
    setRequestApproveData({
      ...requestApproveData,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeAltMail = () => {
    setRequestApproveData({
      ...requestApproveData,
      enableAlternateEmailId: !requestApproveData.enableAlternateEmailId,
    });
  };
  const onInputChecked = (e) => {
    setRequestApproveData({
      ...requestApproveData,
      [e.target.id]: e.target.checked,
    });
  };

  const checkValidity = (level, func, type) => {
    const { mail, post, sms, call } = requestApproveData;
    checkComChannels(level, { mail, post, sms, call })
      ? getParsForApi(func, type)
      : toast.error("Atleast one mode of communication has to be selected");
  };

  const onAction = (actiontype) => {
    var form = document.getElementById("form1");
    if (form.reportValidity()) {
      checkValidity(props.level, "edit", actiontype);
    }
  };

  const getParsForApi = (func, action) => {
    const comments = props.category.comments;
    const leveltype = "category";
    const finaldata = jsondata({
      ...requestApproveData,
      comments,
      func,
      action,
    });
    console.log(finaldata);
    if (action === "save" && finaldata.adminMetaData.subCategoryName === "") {
      toast.error("Please Enter Category Name");
    } else {
      apicall(
        finaldata,
        func,
        props.onClose,
        props.notify,
        props.brand,
        action,
        leveltype
      );
    }
  };
  return (
    <Modal
      className="modalpopup modal-permissionlevel1"
      show={props.show}
      onHide={() => props.onClose()}
    >
      <Modal.Header closeButton>
        <p>Final Approve / Reject</p>
      </Modal.Header>
      <Modal.Body>
        <Form id="form1">
          <Row>
            <Col md={12}>
              <DeleteText {...props} />
              <p>Level {props.level}</p>
            </Col>
            <HelpSection />
          </Row>

          <CategorySec
            category={requestApproveData.categoryname}
            onChange={(e) => handleRequestChange(e)}
            onlyDelete={deleteOrView(props.optionType)}
          />
          <DescriptionSec
            description={requestApproveData.description}
            onChange={(e) => handleRequestChange(e)}
            onlyDelete={deleteOrView(props.optionType)}
          />
          {props.level === 1 && (
            <>
              <EnableEmailSec
                altEmail={requestApproveData.enableAlternateEmailId}
                onChange={(e) => onChangeAltMail()}
                onlyDelete={deleteOrView(props.optionType)}
              />

              <DefaultCommunicationModes
                mail={requestApproveData.mail}
                post={requestApproveData.post}
                call={requestApproveData.call}
                sms={requestApproveData.sms}
                default={requestApproveData.default}
                onChecked={(e) => onInputChecked(e)}
                onDefaultCommChecked={(e) =>
                  onDefaultCommChecked(
                    e,
                    requestApproveData,
                    setRequestApproveData
                  )
                }
                onlyDelete={deleteOrView(props.optionType)}
              />
            </>
          )}

          <DateSec
            startDate={requestApproveData.startDate}
            endDate={requestApproveData.endDate}
            type={editOrDelete(props.optionType) ? datevalue : "text"}
            onChange={(e) => handleRequestChange(e)}
            onlyDelete={onlyDeleteconditon(props.optionType)}
            onlyView={onlyView(props.optionType)}
            onlyAddconditon={onlyAddconditon(props.optionType)}
          />

          {props.level > 2 && (
            <FinalSelection
              onlyDelete={deleteOrView(props.optionType)}
              onChecked={(e) => onInputChecked(e)}
            />
          )}
          <CommentSec
            commentText={requestApproveData.commentText}
            onChange={(e) => handleRequestChange(e)}
            editOrDelete={editOrDelete(props.optionType)}
            onlyDelete={deleteOrView(props.optionType)}
          />
          <ActionButtonSec onAction={onAction} />
        </Form>
      </Modal.Body>
    </Modal>
  );
}
export default ApproveRejectScreen;

import React, { useState, useEffect } from "react";
import "../scss/DashboardComponent.scss";
import { FaRegEdit, FaRegEye } from "react-icons/fa";
import { dateFormat } from "../../CommonBlocks/js/CommonBlock";
import { AxiosGet, AxiosPostMetadata } from "../../AxiosMethods/ApiCalls";
import AddPermissionLevels from "../../AddPermissionLevels/js/AddPermissionLevels";
import ApproveRejectScreen from "../../ApproveRejectScreen/js/ApproveRejectScreen";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const userRole = "ECPC_SUPER_ADMIN";
const userId = 1234567;
const navalue = "N/A";

const checkUndefined = (value) => {
  return value ? value : navalue;
};

const checkDateUndefined = (datevalue) => {
  return datevalue ? dateFormat(datevalue) : navalue;
};
const GettabTitle = ({ type }) => {
  if (type === "actionItem") {
    return <p>Action Items</p>;
  } else if (type === "recentActivity") {
    return <p>All My Portal Activity</p>;
  } else {
    return <p>All Portal Activity</p>;
  }
};
const GetlActionColHeads = ({ type }) => {
  if (type === "actionItem") {
    return <th>Action</th>;
  } else if (type === "recentActivity") {
    return (
      <>
        <th>Status</th>
        <th>Modify</th>
      </>
    );
  } else {
    return (
      <>
        <th>Status</th>
        <th>View</th>
      </>
    );
  }
};
const GetActionColBody = (props) => {
  if (props.type === "actionItem") {
    return (
      <td
        className="review"
        onClick={(e) => {
          props.setshowTrue();
          props.modifyData(props.recentlist, props.brand, props.type);
        }}
      >
        <a href="#">Review to Approve/Reject</a>
      </td>
    );
  } else if (props.type === "recentActivity") {
    return (
      <>
        <td>{checkUndefined(props.recentlist.status)}</td>
        <td
          className="edit"
          onClick={(e) => {
            props.setshowTrue();
            props.modifyData(props.recentlist, props.brand, props.type);
          }}
        >
          <FaRegEdit />
        </td>
      </>
    );
  } else {
    return (
      <>
        <td>{checkUndefined(props.recentlist.status)}</td>
        <td
          className="view"
          onClick={() => {
            props.setshowTrue();
            props.modifyData(props.recentlist, props.brand, props.type);
          }}
        >
          <FaRegEye />
        </td>
      </>
    );
  }
};
const ActivityTable = (props) => {
  const modalshow =
    props.isModalShow && props.resposnsedata && props.resposnsedata.level;
  return (
    <>
      <div className="recent-activity">
        <GettabTitle type={props.type} />
        <table className="table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Level 2</th>
              <th>Level 3</th>
              <th>Level 4</th>
              <th>Level 5</th>
              <th>Created By</th>
              <th>Created Date</th>
              <th>Last Modified</th>
              <GetlActionColHeads type={props.type} />
            </tr>
          </thead>
          <tbody>
            {props.fetcheData &&
              props.fetcheData
                .sort(
                  (a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate)
                )
                .slice(0, 4)
                .map((recentlist, index) => {
                  return (
                    <tr key={index}>
                      <td>{checkUndefined(recentlist.levels[0])}</td>
                      <td>{checkUndefined(recentlist.levels[1])}</td>
                      <td>{checkUndefined(recentlist.levels[2])}</td>
                      <td>{checkUndefined(recentlist.levels[3])}</td>
                      <td>{checkUndefined(recentlist.levels[4])}</td>
                      <td>{checkUndefined(recentlist.createdBy)}</td>
                      <td>{checkDateUndefined(recentlist.createdDate)}</td>
                      <td>{checkDateUndefined(recentlist.modifiedDate)}</td>
                      <GetActionColBody {...props} recentlist={recentlist} />
                    </tr>
                  );
                })}
          </tbody>
        </table>
        {props.isNoDataFound && <strong>No Data Found</strong>}
        {props.type === "recentActivity" && modalshow && (
          <AddPermissionLevels
            show={props.isModalShow}
            onClose={props.onclose}
            category={props.resposnsedata}
            optionType="Edit"
            brand={props.brand}
            level={props.resposnsedata.level}
            notify={props.notify}
          />
        )}
        {props.type === "actionItem" && modalshow && (
          <ApproveRejectScreen
            show={props.isModalShow}
            onClose={props.onclose}
            category={props.resposnsedata}
            optionType="Edit"
            brand={props.brand}
            level={props.resposnsedata.level}
            notify={props.notify}
          />
        )}
        {props.type === "recentUpdate" && modalshow && (
          <AddPermissionLevels
            show={props.isModalShow}
            onClose={props.onclose}
            category={props.resposnsedata}
            optionType="View"
            brand={props.brand}
            level={props.resposnsedata.level}
            notify={props.notify}
          />
        )}
      </div>
      <div className="recent-activity-list">
        <p>
          To See all My activities{" "}
          <a href="/dashboard-admin/report-audit">Click here</a>
        </p>
      </div>
    </>
  );
};
const checkinrecentUpdate = (fetcheData, id) => {
  console.log(fetcheData);
  return fetcheData.some((e) => {
    if (e.id === id || e.id.includes(id)) {
      return false;
    } else {
      return e.id.split("#").some((t) => {
        return id.split("#").some((d) => {
          if (d !== "CATEGORY") {
            return d === t && (e.status === "Reject" || e.status === "Draft");
          } else {
            return false;
          }
        });
      });
    }
  });
};
const checkinActionItems = (fetcheData, id) => {
  console.log(fetcheData);
  return fetcheData.some((e) => {
    if (e.id === id || e.id.includes(id)) {
      return false;
    } else {
      return e.id.split("#").some((t) => {
        console.log(e.id);
        return id.split("#").some((d) => {
          if (d !== "CATEGORY") {
            return d === t;
          } else {
            return false;
          }
        });
      });
    }
  });
};
function DashboardComponent(props) {
  const [fetcheData, setFetchedData] = useState([]);
  const [isNoDataFound, setNoDataFound] = useState(false);
  const [showViewLevel, SetShowViewLevel] = useState(false);
  const [showModifyLevel, SetShowModifyLevel] = useState(false);
  const [showActionLevel, SetShowActionLevel] = useState(false);
  useEffect(() => {
    getApiCall();
  }, [props.brand, props.type]);

  const getApiCall = () => {
    const getDataApi = AxiosGet({
      brand: props.brand,
      type: `${props.type}&userId=${userId}&role=${userRole}`,
    });
    getDataApi.then((result) => {
      result.data.data
        ? setFetchedData(result.data.data)
        : setNoDataFound(true);
    });
  };

  const [resposnsedata, setData] = useState([]);
  const modifyData = async (data, brand, type) => {
    let isCantApprove = true;
    if (type === "actionItem") {
      isCantApprove =
        !checkinActionItems(fetcheData.actionItem, data.id) &&
        !checkinrecentUpdate(fetcheData.recentUpdate, data.id);
    }
    if (isCantApprove) {
      const finalData = {
        adminMetaData: {
          id: data.id,
          brand: brand,
        },
      };
      const getMetaData = await AxiosPostMetadata(finalData);
      setData(getMetaData.data);
    } else {
      console.log(isCantApprove);
      toast.error("Please Approve Parent Category");
    }
  };
  const notify = (res, resType) => {
    if (resType === "success") {
      toast.success(res);
      getApiCall();
    } else {
      toast.error(res);
    }
  };
  return (
    <div className="dashboard-component" id="dashboard-page">
      <ToastContainer />
      <ActivityTable
        type="actionItem"
        fetcheData={fetcheData.actionItem}
        resposnsedata={resposnsedata}
        brand={props.brand}
        onclose={() => {
          SetShowActionLevel(false);
          setData([]);
        }}
        isModalShow={showActionLevel}
        setshowTrue={() => SetShowActionLevel(true)}
        isNoDataFound={isNoDataFound}
        modifyData={modifyData}
        notify={notify}
      />
      <ActivityTable
        type="recentActivity"
        fetcheData={fetcheData.recentActivity}
        resposnsedata={resposnsedata}
        brand={props.brand}
        onclose={() => {
          SetShowModifyLevel(false);
          setData([]);
        }}
        isModalShow={showModifyLevel}
        setshowTrue={() => SetShowModifyLevel(true)}
        isNoDataFound={isNoDataFound}
        modifyData={modifyData}
        notify={notify}
      />
      <ActivityTable
        type="recentUpdate"
        fetcheData={fetcheData.recentUpdate}
        resposnsedata={resposnsedata}
        brand={props.brand}
        onclose={() => {
          SetShowViewLevel(false);
          setData([]);
        }}
        setshowTrue={() => SetShowViewLevel(true)}
        isModalShow={showViewLevel}
        isNoDataFound={isNoDataFound}
        modifyData={modifyData}
        notify={notify}
      />
    </div>
  );
}

export default DashboardComponent;
